@mixin button-variant($color, $background, $border) {
	color: $color;
	background-color: $background;
	border-color: $border;

	&:hover,
	&:focus,
	&:active,
	&.active,
	.open .dropdown-toggle {
		color: $color;
		background-color: darken($background, 10%);
        border-color: darken($border, 12%);
	}
	&:active,
	&.active,
	.open .dropdown-toggle {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: $background;
			border-color: $border;
		}
	}

	.badge {
		color: $background;
		background-color: $color;
	}
}

@mixin transition-all() {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}